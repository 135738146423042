var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "list" },
        [
          _vm._m(1),
          _vm._l(_vm.filterList, function(item) {
            return _c("div", { key: item.OrderNo, staticClass: "list_item" }, [
              _c("div", [
                _c("div", { staticClass: "list_item_platform" }, [
                  _vm._v(_vm._s(item.Platform))
                ]),
                _c("span", [_vm._v("No." + _vm._s(item.OrderNo))]),
                _c("br", { staticClass: "hidden-mobile-down" }),
                _c(
                  "span",
                  {
                    staticClass: "openDetail_btn hidden-mobile-down",
                    on: {
                      click: function($event) {
                        return _vm.DetailHandler(item.OrderNo)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "pr-1",
                      attrs: {
                        src: require("../../assets/會員相關/maximize.svg")
                      }
                    }),
                    _vm._v("詳細內容\n        ")
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "openDetail_btn hidden-mobile-up",
                    on: {
                      click: function($event) {
                        return _vm.toDetailPage(item.OrderNo)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "pr-1",
                      attrs: {
                        src: require("../../assets/會員相關/maximize.svg")
                      }
                    }),
                    _vm._v("詳細內容\n        ")
                  ]
                )
              ]),
              _c("div", [_vm._v(_vm._s(item.Date))]),
              _c("div", [
                item.ReceiverMemberId
                  ? _c("div", { staticClass: "list_item_platform" }, [
                      _vm._v("\n          贈送給\n        ")
                    ])
                  : _vm._e(),
                _vm._v("\n        " + _vm._s(_vm.getTarget(item)) + "\n      ")
              ]),
              _c("div", [
                item.ReceiverMemberId
                  ? _c(
                      "button",
                      { staticClass: "hasReceiverMember notReady" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.ProductName.split("-")[0]) +
                            "\n        "
                        )
                      ]
                    )
                  : _c(
                      "button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.toUnlockPage(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.ProductName.split("-")[0]) +
                            "\n        "
                        )
                      ]
                    )
              ])
            ])
          }),
          _vm.filterList.length == 0
            ? _c("div", { staticClass: "noRecord" }, [
                _vm._v("無任何訂單紀錄喔")
              ])
            : _vm._e()
        ],
        2
      ),
      _c("v-pagination", {
        staticClass: "my-4",
        attrs: { length: _vm.pageLength },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      }),
      _vm.DetailConent
        ? _c("FixedPopup", [
            _c(
              "div",
              { staticClass: "popupBox" },
              [
                _vm.showOrderData && _vm.DetailConent
                  ? _c("DetailConent", {
                      attrs: { orderData: _vm.showOrderData },
                      on: {
                        sendEmail: function($event) {
                          return _vm.sendEmailHandler()
                        }
                      }
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "close_btn",
                    on: {
                      click: function($event) {
                        _vm.DetailConent = false
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/會員相關/circle_close.svg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.openSendEmailResult
        ? _c("SendEmailResult", {
            attrs: { text: "謝謝您的回報，我們將盡快為您處理" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _c("img", {
        attrs: { src: require("../../assets/會員相關/titleIcon.svg"), alt: "" }
      }),
      _vm._v("\n    購買紀錄\n  ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list_head hidden-mobile-down" }, [
      _c("div", [_vm._v("訂單編號")]),
      _c("div", [_vm._v("購買日期")]),
      _c("div", [_vm._v("星盤對象")]),
      _c("div", [_vm._v("產品內容")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }