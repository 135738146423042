var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.afterApi
    ? _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "importList" }, [
          _c("div", { staticClass: "importList__box" }, [
            _c("div", { staticClass: "importList__photo" }, [
              _c("div", { staticClass: "photo__inset" }, [
                _c(
                  "div",
                  { staticClass: "photo__bg" },
                  [
                    _c(
                      "v-avatar",
                      { staticClass: "photo", attrs: { size: "168" } },
                      [_c("img", { attrs: { src: _vm.headImage } })]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "importList__photo__platform" }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loginMethods == "Line",
                      expression: "loginMethods == 'Line'"
                    }
                  ],
                  attrs: { src: require("@/assets/歸戶平台/LINE.png"), alt: "" }
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loginMethods === "Facebook",
                      expression: "loginMethods === 'Facebook'"
                    }
                  ],
                  attrs: {
                    src: require("@/assets/歸戶平台/facebook.png"),
                    alt: ""
                  }
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loginMethods === "Google",
                      expression: "loginMethods === 'Google'"
                    }
                  ],
                  attrs: {
                    src: require("@/assets/歸戶平台/check.svg"),
                    alt: ""
                  }
                })
              ])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userData.Account,
                    expression: "userData.Account"
                  }
                ],
                staticClass: "importList__id"
              },
              [_vm._v("您的帳號 ID")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userData.Account,
                    expression: "userData.Account"
                  }
                ],
                staticClass: "importList__account"
              },
              [_vm._v("\n        " + _vm._s(_vm.userData.Account) + "\n      ")]
            ),
            _vm._m(1),
            _c("div", { staticClass: "importList__list" }, [
              _c("div", { staticClass: "importCard" }, [
                _vm._m(2),
                _c("div", { staticClass: "info_area" }, [
                  _c("div", { staticClass: "main_group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "control" }, [
                      _vm.userData.Platforms &&
                      _vm.userData.Platforms.includes("Line")
                        ? _c("div", { staticClass: "control__importfinish" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/歸戶平台/check.svg"),
                                alt: ""
                              }
                            }),
                            _vm._v(
                              "\n                  已完成歸戶\n                "
                            )
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "control__importBtn",
                              on: {
                                click: function($event) {
                                  return _vm.toImport("Line")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  立即歸戶\n                "
                              )
                            ]
                          )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "importCard" }, [
                _vm._m(4),
                _c("div", { staticClass: "info_area" }, [
                  _c("div", { staticClass: "main_group" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "control" }, [
                      _vm.userData.Platforms &&
                      _vm.userData.Platforms.includes("Facebook")
                        ? _c("div", { staticClass: "control__importfinish" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/歸戶平台/check.svg"),
                                alt: ""
                              }
                            }),
                            _vm._v(
                              "\n                  已完成歸戶\n                "
                            )
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "control__importBtn",
                              on: {
                                click: function($event) {
                                  return _vm.toImport("Facebook")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  立即歸戶\n                "
                              )
                            ]
                          )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "importCard" }, [
              _vm._m(6),
              _c("div", { staticClass: "info_area" }, [
                _c("div", { staticClass: "main_group" }, [
                  _vm._m(7),
                  _c("div", { staticClass: "control" }, [
                    _vm.userData.Platforms &&
                    _vm.userData.Platforms.includes("Google")
                      ? _c("div", { staticClass: "control__importfinish" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/歸戶平台/check.svg"),
                              alt: ""
                            }
                          }),
                          _vm._v("\n                已完成歸戶\n              ")
                        ])
                      : _c(
                          "div",
                          {
                            staticClass: "control__importBtn",
                            on: {
                              click: function($event) {
                                return _vm.toImport("Google")
                              }
                            }
                          },
                          [_vm._v("\n                立即歸戶\n              ")]
                        )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page-header custom-container hidden-pad-down" },
      [
        _c("img", {
          staticClass: "img-title",
          attrs: { src: require("@/assets/header_member.svg"), alt: "" }
        }),
        _c("span", [_vm._v("平台可歸戶帳號")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "importList__ListTitle" }, [
      _c("span", [_vm._v("平台可註冊帳號")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pic_box" }, [
      _c("img", {
        attrs: { src: require("@/assets/歸戶平台/LINE.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "base" }, [
      _c("div", { staticClass: "name" }, [_vm._v("Line")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pic_box" }, [
      _c("img", {
        attrs: { src: require("@/assets/歸戶平台/facebook.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "base" }, [
      _c("div", { staticClass: "name" }, [_vm._v("Facebook")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pic_box" }, [
      _c("img", {
        attrs: { src: require("@/assets/歸戶平台/GOOGLE.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "base" }, [
      _c("div", { staticClass: "name" }, [_vm._v("Google")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }